.navbar {
    width: 100%;
    position: sticky;
    z-index: 1000;
    top: 0;
}

.navbar.scrolled {
    background: #1313134a;
    backdrop-filter: blur(5px);
}

.navbar_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

}

.sections {
    padding: 10px;
    display: flex;
    gap: 25px;
}

.sections span {
    font-family: "Cairo", sans-serif;
    font-size: 18px;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    color: #d5d5d5;
    position: relative;
    display: inline-block;
}

.sections span::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: #0AD800;
    width: 0%;
    transition: width 0.3s ease;
}

.sections span:hover::after {
    width: 100%;
}

.translate {
    cursor: pointer;
    color: #d5d5d5;
}

.translate:hover {
    color: #0AD800;
}

.sections_menu {
    display: none;
    transition: display 0.3s ease-in-out;
}

.hamburger {
    display: none;
}

@media screen and (max-width: 600px) {
    .sections {
        display: none;
    }

    .navbarBox {
        display: none;
    }

    .navbar.scrolled {
        backdrop-filter: blur(5px);
    }

    .sections_menu {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 75%;
        background-color: #9d9f9d37;
        backdrop-filter: blur(15px);
        z-index: 100;
        -webkit-box-shadow: -300px -3px 45px 21px #86878637;
        -moz-box-shadow: -300px -3px 45px 21px #86878637;
        box-shadow: -300px -3px 45px 21px #86878637;
        justify-content: right;
        display: none;
    }

    .hamburger {
        display: flex;
        z-index: 1000;
        color: #e1e1e1;
    }

    .sections_menu_content {
        height: 100%;
        padding: 80px 50px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: start;
        gap: 40px;
    }

    .sections_menu_content span {
        font-family: "Cairo", sans-serif;
        font-size: 20px;
        cursor: pointer;
        font-weight: 600;
        text-decoration: none;
        color: #0AD800;
        position: relative;
        display: inline-block;
    }
}