@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

@font-face {
  font-family: 'ocr';
  src:
    url('../src/assets/fonts/ocr-aregular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
}