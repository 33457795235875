.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1e1e1e;
    z-index: -3;
}

.topBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 150%;
    height: 150%;
    background-color: #000000da;
    z-index: 0;
    transition: 'transform 0.1s ease-out';
    background-size: cover;
}

.circle1 {
    position: absolute;
    width: 58vw;
    height: 58vw;
    /* background: #219b44ac; */
    border-radius: 100%;
    bottom: -200px;
    right: -120px;
    filter: blur(100px);
    transition: 'transform 0.1s ease-out'
}

.circle2 {
    position: absolute;
    width: 58vw;
    height: 58vw;
    /* background: #9b2146ad; */
    border-radius: 100%;
    bottom: -200px;
    left: -120px;
    filter: blur(100px);
    transition: 'transform 0.1s ease-out'
}

.circle3 {
    position: absolute;
    width: 58vw;
    height: 58vw;
    /* background: #21609bac; */
    border-radius: 100%;
    top: -50%;
    left: 35%;
    filter: blur(100px);
}

@media (max-width: 600px) {

    .topBackground {
        background-color: #000000bb;
    }

    .circle1 {
        width: 90vw;
        height: 90vw;
    }

    .circle2 {
        width: 80vw;
        height: 80vw;
    }

    .circle3 {
        width: 70vw;
        height: 70vw;
        top: -20%;
        left: 20%;
    }

}